<template>
  <nav v-if="this.userIsLoggedIn" :class="{ 'sidebar-left--collapsed': isCollapsed }"
    class="sidebar-left position-fixed vh-100 d-flex flex-column justify-content-between">
    <div class="row px-0 mt-4">
      <div class="col mx-4 mx-md-0">
        <div class="mx-3 row justify-content-between justify-content-md-center align-items-center mb-4">
          <div class="col-4 col-md-7 px-0 d-flex justify-content-center">
            <img id="logo" src="../assets/images/logo-pwnx-darkmode.svg" alt="PWNX" class="sidebar-left__logo--image" />
          </div>
          <div v-responsive.xs.sm class="col-3 px-0 text-right">
            <i class="pointer fa-solid fa-xmark" @click="collapseSidebar"></i>
          </div>
        </div>
        <div class="col mx-0 px-2 d-flex flex-column">
          <div class="row sidebar-left__element d-flex m-1 py-2 px-3 align-items-center"
            :class="{ active: currentPage.includes('home') }" @click="handleRouter('home')">
            <div class="col-1 d-flex px-0">
              <i class="fas fa-home"></i>
            </div>
            <div class="col pl-0">
              <h2 class="mb-0 pl-0 pointer ml-md-3">home</h2>
            </div>
          </div>
          <div class="row sidebar-left__element d-flex bh-button m-1 py-2 px-3 align-items-center collapsed"
            :class="{ active: currentPage.includes('courses') }" id="courses__btn" v-if="isUser() && !user.inCorporate">
            <div class="col-12 px-0 d-flex" @click="expandCollapsed('courses__btn')">
              <div class="col-1 d-flex px-0">
                <i class="fa-solid fa-dumbbell"></i>
              </div>
              <div class="col pl-0">
                <h2 class="mb-0 pl-0 pointer ml-md-3">beginners hub</h2>
              </div>
            </div>
            <div class="col-12 px-0 mt-2 collapsable">
              <h2 class="simple-hover mb-1 px-0 ml-md-3 mr-4" @click="handleRouter('courses/web-security')">
                web security 101
              </h2>
              <h2 class="simple-hover mb-1 px-0 ml-md-3 mr-4" @click="handleRouter('courses/web-security/labs')">
                web labs
              </h2>
              <h2 class="simple-hover mb-1 px-0 ml-md-3 mr-4"
                @click="handleRouter('courses/web-security/achievements')">
                achievements
              </h2>
            </div>
          </div>
          <div class="row sidebar-left__element d-flex m-1 py-2 px-3 align-items-center" :class="{
            active: currentPage.includes('/labs/web3')
          }" id="premium__btn" v-if="isUser() && (user.inCorporate || this.$parent.isPremium())">

            <div class="col-12 px-0 d-flex" @click="handleRouter('labs/web3')">
              <div class="col-1 d-flex px-0">
                <i class="fa-brands fa-bitcoin"></i>
              </div>
              <div class="col pl-0">
                <h2 class="mb-0 pl-0 pointer ml-md-3">web3</h2>
              </div>
            </div>
          </div>
          <div class="row sidebar-left__element d-flex buy-tokens m-1 py-2 px-3 align-items-center" :class="{
            active: currentPage.includes('/labs/retired')
          }" id="premium__btn" v-if="isUser() && (user.inCorporate || this.$parent.isPremium())">

            <div class="col-12 px-0 d-flex" @click="handleRouter('labs/retired')">
              <div class="col-1 d-flex px-0">
                <i class="fa-regular fa-folder-open"></i>
              </div>
              <div class="col pl-0">
                <h2 class="mb-0 pl-0 pointer ml-md-3">old but gold</h2>
              </div>
            </div>
          </div>
          <div class="row sidebar-left__element d-flex partners-button m-1 py-2 px-3 align-items-center collapsed"
            :class="{ active: currentPage.includes('partners') }" id="partners__btn" v-if="false">
            <div class="col-12 px-0 d-flex" @click="expandCollapsed('partners__btn')">
              <div class="col-1 d-flex px-0">
                <i class="fa-solid fa-handshake-angle"></i>
              </div>
              <div class="col pl-0">
                <h2 class="mb-0 pl-0 pointer ml-md-3">partners</h2>
              </div>
            </div>
            <div class="col-12 px-0 mt-2 collapsable">
              <h2 class="simple-hover mb-1 px-0 ml-md-3 mr-4" @click="handleRouter('partners/spot-the-vuln')">
                spot the vuln
              </h2>
            </div>
          </div>
          <!-- <div -->
          <!--   class="row sidebar-left__element d-flex m-1 py-2 px-3 align-items-center" -->
          <!--   :class="{ active: currentPage.includes(['/courses', 'Web Security'].join('/')) }" -->
          <!--   v-if="isUser()" -->
          <!--   @click="handleRouter(['courses', 'Web Security'].join('/'))" -->
          <!-- > -->
          <!--   <div class="col-1 d-flex px-0"> -->
          <!--     <span style="font-size:16px">💪</span> -->
          <!--   </div> -->
          <!--   <div class="col pl-0"> -->
          <!--     <h2 class="mb-0 pl-0 pointer ml-md-3"> Cyber Beginnings</h2> -->
          <!--   </div> -->
          <!-- </div> -->

          <!-- <div -->
          <!--   class="row sidebar-left__element d-flex m-1 py-2 px-3 align-items-center" -->
          <!--   :class="{ active: currentPage.includes(['/courses', 'Web Security'].join('/')) }" -->
          <!--   @click="handleRouter(['courses', 'Web Security'].join('/'))" -->
          <!-- > -->
          <!--   <div class="col-1 d-flex px-0"> -->
          <!--     <i class="fas fa-handshake"></i> -->
          <!--   </div> -->
          <!--   <div class="col pl-0"> -->
          <!--     <h2 class="mb-0 pl-0 pointer ml-md-3">partnership</h2> -->
          <!--   </div> -->
          <!-- </div> -->
          <div id="ch-collapsable" v-if="shouldSeeNormalUserButton()"
            class="row sidebar-left__element m-1 py-2 px-3 align-items-center collapsed" :class="{
              active:
                currentPage.includes('labs') &&
                !currentPage.includes('premium') &&
                !currentPage.includes('courses') &&
                !currentPage.includes('labs/'),
            }">
            <div class="row sidebar-left__element m-0 align-items-center" @click="expandCollapsed('ch-collapsable')">
              <div class="col-1 d-flex p-0 m-0">
                <i class="fas fa-vial m-0 p-0"></i>
              </div>
            </div>
            <div class="col pl-0" @click="$router.push('/labs')">
              <h2 class="mb-0 pl-0 pointer ml-md-3">challenges</h2>
            </div>
            <!-- <div class="col-12 collapsable px-0 mt-2"> -->
            <!--   <h2 -->
            <!--     class="simple-hover mb-1 px-0 ml-md-3 mr-4" -->
            <!--     @click="handleRouter('labs')" -->
            <!--   > -->
            <!--     free labs -->
            <!--   </h2> -->
            <!--   <h2 -->
            <!--     class="simple-hover mb-1 px-0 ml-md-3 mr-4" -->
            <!--     @click="handleRouter('labs/web')" -->
            <!--   > -->
            <!--     web labs -->
            <!--   </h2> -->
            <!-- <h2
              class="simple-hover mb-1 px-0 ml-md-3 mr-4"
              @click="handleRouter('labs/web3')">
              web3 labs
            </h2> -->
            <!--   <h2 -->
            <!--     class="simple-hover mb-1 px-0 ml-md-3 mr-4" -->
            <!--     @click="handleRouter('labs/retired')" -->
            <!--   > -->
            <!--     retired labs -->
            <!--   </h2> -->
            <!-- </div> -->
          </div>
          <div v-if="shouldSeeNormalUserButton()"
            class="row sidebar-left__element d-flex m-1 py-2 px-3 align-items-center"
            :class="{ active: currentPage.includes('badges') }" @click="handleRouter('badges')">
            <div class="col-1 d-flex px-0">
              <i class="fas fa-ribbon"></i>
            </div>
            <div class="col pl-0">
              <h2 class="mb-0 pl-0 pointer ml-md-3">badges</h2>
            </div>
          </div>
          <!-- <div -->
          <!--   v-if="shouldSeeRecruiterBtns()" -->
          <!--   class="row sidebar-left__element d-flex m-1 py-2 px-3 align-items-center" -->
          <!--   :class="{ active: currentPage.includes('recruiter') }" -->
          <!--   @click="handleRouter('recruiter')" -->
          <!-- > -->
          <!--   <div class="col-1 d-flex px-0"> -->
          <!--     <i class="fas fa-solid fa-signal"></i> -->
          <!--   </div> -->
          <!--   <div class="col pl-0"> -->
          <!--     <h2 class="mb-0 pl-0 pointer ml-md-3">recruiter</h2> -->
          <!--   </div> -->
          <!-- </div> -->
          <div v-if="shouldSeeRecruiterBtns() && !this.$parent.user.isHrpRecruiter"
            class="row sidebar-left__element d-flex m-1 py-2 px-3 align-items-center" :class="{
              active:
                currentPage.includes('events') && currentPage.includes('add'),
            }" @click="handleRouter('events/add')">
            <div class="col-1 d-flex px-0">
              <i class="fas fa-plus"></i>
            </div>
            <div class="col pl-0">
              <h2 class="mb-0 pl-0 pointer ml-md-3">new event</h2>
            </div>
          </div>
          <div class="row sidebar-left__element d-flex m-1 py-2 px-3 align-items-center collapsed"
            :class="{ active: currentPage.includes('leaderboard') }" id="leaderboard__btn">
            <div class="col-12 px-0 d-flex" @click="expandCollapsed('leaderboard__btn')">
              <div class="col-1 d-flex px-0">
                <i class="fas fa-crown"></i>
              </div>
              <div class="col pl-0">
                <h2 class="mb-0 pl-0 pointer ml-md-3">leaderboard</h2>
              </div>
            </div>
            <div class="col-12 px-0 mt-2 collapsable">
              <h2 class="simple-hover mb-1 px-0 ml-md-3 mr-4" @click="handleRouter('leaderboards/user')">
                users
              </h2>
              <h2 class="simple-hover mb-1 px-0 ml-md-3 mr-4" @click="handleRouter('leaderboards/team')">
                teams
              </h2>
            </div>
          </div>
          <div @click="handleRouter('teams')" class="row sidebar-left__element d-flex m-1 py-2 px-3 align-items-center"
            :class="{ active: currentPage.includes('teams') }">
            <div class="col-1 d-flex px-0">
              <i class="fas fa-users"></i>
            </div>
            <div class="col pl-0">
              <h2 class="mb-0 pl-0 pointer ml-md-3">teams</h2>
            </div>
          </div>
          <div class="row sidebar-left__element d-flex m-1 py-2 px-3 align-items-center" :class="{
            active:
              currentPage.includes('events') && !currentPage.includes('add'),
            'company-admin': isHrpRecruiter
          }" @click="handleRouter('events')">
            <div class="col-1 d-flex px-0">
              <i class="fas fa-calendar"></i>
            </div>
            <div class="col pl-0">
              <h2 class="mb-0 pl-0 pointer ml-md-3">events</h2>
            </div>
          </div>
          <div class="row sidebar-left__element d-flex m-1 py-2 px-3 company-admin align-items-center"
            :class="{ active: currentPage.includes('company-ranking') }" @click="handleRouter('company-ranking')"
            v-if="isCompanyAllowed">
            <div class="col-1 d-flex px-0">
              <i class="fas fa-chart-bar"></i>
            </div>
            <div class="col pl-0">
              <h2 class="mb-0 pl-0 pointer ml-md-3">Company Rank</h2>
            </div>
          </div>
          <div class="row sidebar-left__element d-flex m-1 py-2 px-3 company-admin align-items-center"
            :class="{ active: currentPage.includes('company-admin') }" @click="handleRouter('company-admin')"
            v-if="isCompanyAccount">
            <div class="col-1 d-flex px-0">
              <i class="fa-solid fa-building"></i>
            </div>
            <div class="col pl-0">
              <h2 class="mb-0 pl-0 pointer ml-md-3">Company Admin</h2>
            </div>
          </div>
          <div class="row sidebar-left__element d-flex m-1 py-2 px-3 align-items-center purple"
            :class="{ active: currentPage.includes('user/' + $parent.user.id) }"
            @click="handleRouter(`user/${$parent.user.id}`)" v-if="isUser() && !$parent.user.inCorporate">
            <div class="col-1 m-0 d-flex px-0 d-flex justify-content-center align-items-center">
              <i class="fa-solid fa-chart-line"></i>
            </div>
            <div class="col pl-0">
              <h2 class="mb-0 pl-0 pointer ml-md-3">growth tracking</h2>
            </div>
          </div>
          <div class="row sidebar-left__element d-flex m-1 py-2 px-3 align-items-center pink"
            :class="{ active: currentPage.includes('learning-paths') }" @click="handleRouter('learning-paths')"
            v-if="isUser() && user.inCorporate">
            <div class="col-1 m-0 d-flex px-0 d-flex justify-content-center align-items-center">
              <i class="fa-solid fa-brain"></i>
            </div>
            <div class="col pl-0">
              <h2 class="mb-0 pl-0 pointer ml-md-3">Learning Path</h2>
            </div>
          </div>
          <div class="row jobs-menu sidebar-left__element d-flex m-1 py-2 px-3 align-items-center" :class="{
            active:
              currentPage.includes('jobs')
          }" @click="handleRouter('jobs')" v-if="isUser() || isRecruiter()">
            <div class="col-1 d-flex px-0">
              <i class="fa-solid fa-suitcase"></i>
            </div>
            <div class="col pl-0">
              <h2 class="mb-0 pl-0 pointer ml-md-3">job opportunities</h2>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="this.userIsLoggedIn">
      <div class="col-12">
        <div class="row mx-0 user-details__container px-3 pb-3 pt-0">
          <div class="col-12 d-flex justify-content-center py-4 pointer" @click="expandCollapsed('user-details')">
            <span id="line" class="w-75"></span>
          </div>
          <div class="col-12 mx-0">
            <div class="row d-flex align-items-center" id="user-details">
              <div class="col-1 col-md-2 px-0 d-flex align-items-center justify-content-center">
                <span :class="this.$parent.isPremium() ? 'premium-border' : null" class="profile-picture pointer"
                  @click="expandCollapsed('user-details')" :style="{
                    'background-image': `url(${this.$parent.user.picture}`,
                  }"></span>
              </div>
              <div class="col">
                <div class="row">
                  <div class="col">
                    <h2 class="mb-0">{{ this.$parent.user.username }}</h2>
                  </div>
                </div>
                <div class="row" v-if="shouldSeeNormalUserButton()">
                  <div class="col">
                    <p class="mb-0">{{ this.$parent.user.points }}pts</p>
                  </div>
                </div>
              </div>
              <div class="col-12 collapsable mt-4">
                <h2 v-if="shouldSeeNormalUserButton()" class="simple-hover pb-1 mb-0" @click="handleRouter('faq')">
                  FAQ
                </h2>
                <h2 class="simple-hover pb-1 mb-0" @click="handleRouter(`user/${$parent.user.id}/edit`)">
                  update profile
                </h2>
                <h2 v-responsive.xs.sm class="mb-0 simple-hover pb-1" @click="handleRouter('settings')">
                  Settings
                </h2>
                <h2 v-responsive.xs.sm @click="$parent.logout()" class="mb-0 simple-hover pb-1">
                  Logout
                </h2>
                <h2 v-if="$parent.user.team && shouldSeeNormalUserButton()" class="simple-hover pb-1 mb-0"
                  @click="handleRouter(`team/${$parent.user.team}`)">
                  my team
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import UserType from "../enums/user";

export default {
  name: "LeftMenu",
  computed: {
    isHrpRecruiter() {
      if (!this.user) return false
      return this.user.isHrpRecruiter
    },
    isCompanyAllowed() {
      if (!this.isCompanyAccount) return false;
      if (this.user.role === UserType.CORPORATE) return true;
      return this.user.isHrpCompany;
    },
    currentPage() {
      return this.$route.path;
    },
    isCompanyAccount() {
      const allowedRoles = [UserType.CORPORATE, UserType.HR, UserType.HRPLUS, UserType.ENTERPRISE];
      if (!this.user) return false;
      if (!allowedRoles.some((role) => this.user.role === role)) return false;
      return true;
    },
    ...mapState({
      user: (state) => state.user,
    }),
    ...mapGetters({
      userIsLoggedIn: "user/isLoggedIn",
    }),
  },
  props: {
    isCollapsed: {
      type: Boolean,
      required: false,
      default: true
    },
  },
  data() {
    return {
      leadMenuActive: false,
      notificationCount: null,
    };
  },
  methods: {
    isUser() {
      if (!this.user) return false
      return this.user.role === UserType.USER
    },
    isRecruiter() {
      const user = this.user;
      if (!user) return false;
      return user.role === UserType.RECRUITER
    },
    shouldSeeNormalUserButton() {
      const { role: userRole } = this.$parent.user;
      return userRole == UserType.USER || userRole == UserType.ADMIN;
    },
    shouldSeeOldPremiumBtn() {
      const uPremiums = this.$parent.user.premium;
      return uPremiums.length > 0;
    },
    shouldSeeRecruiterBtns() {
      const { role: userRole } = this.$parent.user;
      return userRole == UserType.RECRUITER;
    },
    collapseSidebar() {
      this.$emit("collapse-sidebar")
    },
    expandCollapsed(target) {
      let collapsed = document.getElementById(`${target}`);
      if (collapsed.classList.contains("collapsed")) {
        collapsed.classList.remove("collapsed");
        collapsed.classList.add("closed");
      } else {
        collapsed.classList.add("collapsed");
        collapsed.classList.remove("closed");
      }
    },
    handleRouter(page) {
      this.$router.push(`/${page}`);
    },
    getNotificationsCount() {
      this.notificationCount = this.$parent.$data.notifications.filter(
        (el) =>
          new Date(el.created + "Z").getTime() >=
          localStorage.getItem("lastNotificationCheck")
      ).length;
    },
  },
  created() {
    this.getNotificationsCount();
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/colors";

p {
  font-size: 0.8rem;
}

// .collapsed {
//   width: 0 !important;
// }

.sidebar-left {
  background-color: #15191d;
  width: 250px;
  border-right: 1px solid rgba(white, 0.05);
  z-index: 10;
  transition: all 0.2s ease-in-out;

  @media only screen and (max-width: 767px) {
    width: 100%;
    text-align: right;
    overflow: hidden;

    &--collapsed {
      width: 0 !important;
    }
  }

  .icon {
    font-size: 1.6rem;

    &-dashboard {
      font-size: 1.4rem;
    }
  }

  .menu-active & {
    width: 100%;
  }

  &__hover {
    right: 0;
    width: 10px;
    border-right: 2px solid #4bc66a;
    opacity: 0;
    transition: 0.2s all;

    &:hover {
      opacity: 1;
    }

    &--button {
      top: 130px;
      right: -15px;
      width: 25px;
    }

    &--image {
      width: 25px;
      height: 50%;
      transition: 0.2s all;
      transform: rotate(180deg);

      .menu-active & {
        transform: rotate(0deg);
      }
    }
  }

  &__logo {
    padding: 40px 20px;
    transition: 0.2s all;

    .menu-active & {
      padding-left: 40px;
    }

    .pwnx &--image {
      // max-width: 110px;
    }
  }

  &__menu {
    list-style: none;
    margin: 0;
    padding: 0;

    &>li {
      margin: 2.5rem 0;

      &>a {
        display: flex;
        align-items: center;
        position: relative;
        padding-left: 60px;
        white-space: nowrap;

        &>span {
          /*opacity: 0;*/
          display: none;

          .menu-active & {
            /*opacity: 1;*/
            display: block;
          }
        }

        .menu-active & {
          padding-left: 37px;

          .icon {
            padding-right: 37px;
          }
        }
      }

      &.active {
        border-left: 4px solid #4bc66a;

        &>a {
          padding-left: 56px;
        }

        .menu-active &>a {
          padding-left: 33px;
        }
      }

      &:hover {
        color: #768aa3;

        .icon {
          color: #4bc66a;
        }

        &>a>span {
          text-decoration: underline;
        }
      }
    }
  }
}

.sidebar-left__menu>li>a,
.sidebar-left__submenu>li>a {
  color: #768aa3;
}

.sidebar-left__menu>li.active>a,
.sidebar-left__submenu>li.active>a {
  color: white;

  .icon {
    color: #4bc66a;
  }
}

.sidebar-left__submenu {
  list-style: none;
  margin: 0 0 0 100px;
  padding: 0;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: 0.2s all;
}

.menu-active .sidebar-left__submenu.active {
  opacity: 1;
  max-height: 500px;
}

.sidebar-left__submenu>li {
  margin: 5px 0;
}

.sidebar-left__submenu>li>a {
  font-size: 13px;

  &:hover {
    text-decoration: underline;
  }
}

h2 {
  text-transform: uppercase;
  font-size: 0.7rem;
  opacity: 0.7;
  font-weight: bold;
}

.sidebar-left__element {
  color: $neutral--soft;
  border-radius: 2px;
  transition: all 0.2s;
  white-space: nowrap;
  overflow: hidden;

  i {
    position: relative;
    font-size: 0.9rem;
  }

  &:hover {
    &:not(.closed, .active) {
      background: rgba($neutral--soft, 0.1);
      cursor: pointer;
    }
  }

  &.active {
    color: $soft-green;
    background: rgba($soft-green, 0.2);
  }
}

.collapsed {
  overflow: hidden;

  .collapsable {
    text-transform: uppercase;
    opacity: 0;
    height: 0;
    max-height: 0;
    margin-top: 0 !important;
  }
}

.collapsable {
  transition: all 0.3s;
  white-space: nowrap;
  overflow: hidden;
}

.profile-picture {
  width: 100%;
  border-radius: 50%;
  padding-bottom: 100%;
  background-size: contain;
  background-position: center center;
  background-color: $neutral--medium;
  position: relative;

  &:not(.closed):after {
    content: "";
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    font-size: 0.7rem;
    position: absolute;
    top: -50%;
    left: 50%;
    transform: translateX(-50%);
  }
}

.sidebar-icons {
  font-size: 1.5rem;
  opacity: 0.8;
}

.notifications-icon {
  position: relative;

  &:after {
    position: absolute;
    bottom: -20%;
    right: -40%;
    color: $neutral--dark;
    font-family: "Ubuntu", sans-serif;
    font-weight: bolder;
    content: var(--count);
    background: #000;
    padding: 3px;
    font-size: 0.6rem;
    background: $soft-green;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
}

a {
  color: white;
}

.user-details__container {
  background: $neutral--dark;
}

// ToDo: Restyle this
// .premium {
//   color: $soft-yellow;
//   background: rgba($soft-yellow, 0.2);
//   -webkit-animation: glowing 1s ease-in-out infinite alternate;
//   -moz-animation: glowing 1s ease-in-out infinite alternate;
//   animation: glowing 1s ease-in-out infinite alternate;
//   i {
//     position: relative;
//     font-size: 0.9rem;
//   }

//   &:hover {
//     &:not(.closed, .active) {
//       background: rgba($neutral--soft, 0.1);
//       cursor: pointer;
//     }
//   }
// }

@keyframes glowing {
  from {
    box-shadow: 0 0 0 rgba($soft-yellow, 0.3), 0 0 0 rgba($soft-yellow, 0.3),
      0 0 0 rgba($soft-yellow, 0.3), 0 0 0.3 rgba($soft-yellow, 0.3),
      0 0 0 rgba($soft-yellow, 0.3), 0 0 0 rgba($soft-yellow, 0.3),
      0 0 0 rgba($soft-yellow, 0.3);
  }

  to {
    box-shadow: 0 0 8px rgba($soft-yellow, 0.3), 0 0 8px rgba($soft-yellow, 0.3),
      0 0 8px rgba($soft-yellow, 0.3), 0 0 8px rgba($soft-yellow, 0.3),
      0 0 8px rgba($soft-yellow, 0.3), 0 0 8px rgba($soft-yellow, 0.3),
      0 0 8px rgba($soft-yellow, 0.3);
  }
}

#line {
  background: white;
  height: 2px;
  width: 100%;
  opacity: 0.1;
}

.premium-border {
  border: 2px solid gold;
}

.company-admin {
  color: #4bc66a;
}

.ch-collapsable {
  height: 100%;
  overflow: hidden;

  &.collapsed {
    height: 0;
  }
}


.buy-tokens {
  color: $soft-orange;

  &.active {
    background-color: $soft-orange !important;
    color: $neutral--dark;
  }
}

.buy-tokens:hover {
  background-color: $soft-orange !important;
  color: $neutral--dark;
}

.submit-color {
  color: #5afb4a;
}

.submit-color:hover {
  background-color: #5afb4a !important;
  color: black !important;
}

.bh-button {
  color: $soft-green;
}

.bh-button:hover,
.bh-button.active {
  background-color: $soft-green !important;
  color: black !important;

}

.purple {
  color: $soft-purple;
}

.pink {
  color: $soft-pink;
}

.purple.active {
  background: $soft-purple !important;
  color: black !important;
}

.purple:hover {
  background: $soft-purple !important;
  color: black !important;
}

.jobs-menu {
  border: 1px solid #ebc015;
  color: #ebc015 !important;
}

.jobs-menu.active {
  background: #ebc015 !important;
  color: black !important;
}

.jobs-menu:hover {
  background-color: #ebc015 !important;
  color: black !important;
}

.orange {
  color: $soft-orange !important;

  &.active {
    background-color: $soft-orange !important;
    color: black !important;
  }
}
</style>
